// ***** MATHMATICAL HELPERS ***** //
const usageCostHelper = (rate, basePolymerCost, colorConcentrate) => {
  let colorCost = (rate * colorConcentrate) + (1 - rate) * basePolymerCost;
  colorCost = Math.round(colorCost * 100) / 100;
  
  return colorCost;
}

const ldrCostHelper = (letdownRatio, basePolymerCost, colorConcentrate) => {
  let ldrColorCost = (((colorConcentrate - basePolymerCost) / (letdownRatio + 1)) + basePolymerCost).toFixed(2);

  return ldrColorCost;
}

const sumTotal = (num1, num2) => {
  return num1 + num2;
}

const subtractionTwoNums = (num1, num2) => {
  return num1 - num2;
}

const multiplyTwoNums = (num1, num2) => {
  return num1 * num2;
}

const divideTwoNums = (num1, num2) => {
  return num1 / num2;
}

const numPercentage = num => {
  return num/100;
}

const shotsPerHour = num => {
  if (num > 0) {
    return 3600/num;
  } else {
    return 0;
  }
}

// Check to see if the error message exists via the CSS ID. If it does not add the error message via the addErrorMessage function.
const errorIDCheck = (id, errorData) => {
  if (!document.getElementById(id)) {
    addErrorMessage(errorData);

    return true;
  } else {
    return true;
  }
}

// Create, add and append error message
const addErrorMessage = errorData => {
  let errorDiv = document.createElement('div');
  errorDiv.id = errorData.id;
  errorDiv.classList.add('error-container');
  let errorText = document.createElement('p');
  errorDiv.appendChild(errorText);

  if (errorData.type === 'masterbranch') {
    document.getElementById(`masterbranch-${errorData.iteration}-data-${errorData.dataRow}`).appendChild(errorDiv);
    document.getElementById(errorDiv.id).querySelector('p').innerText = errorData.message;
  } else {
    document.getElementById(`${errorData.type}`).appendChild(errorDiv);      
  }

  document.getElementById(errorDiv.id).querySelector('p').innerText = errorData.message;
}

const errorsHelper = inputData => { 
  let { 
    usageRate1, 
    usageRate2, 
    letdownRatio1, 
    letdownRatio2, 
    colorConcentrate1, 
    colorConcentrate2, 
    basePolymerCost, 
    currentPreColorCost, 
    extResinQty, 
    extResinCost, 
    extUCCCFAPrice,
    extUCCCFADosing,
    extDensityReduction,
    mcPurgingProductAmountCurrent,
    mcPurgingProductCostCurrent,
    mcPurgingProductAmountUCClean,
    mcPurgingProductCostUCClean,
    mcNextResinAmountCurrent, 
    mcNextResinCostCurrent,
    mcNextResinAmountUCClean, 
    mcNextResinCostUCClean, 
    mcDowntimePurgingCurrentTime,
    mcDowntimePurgingCurrentCost,
    mcDowntimePurgingUCCleanTime,
    mcDowntimePurgingUCCleanCost,
    initOriginalCycleTime,
    initOriginalShotWeight,
    initFoamedCycleTime,
    initMachineTimeCost,
    initResinCost,
    initColorantCost,
    initColorantAddRate,
    initCFACost,
    initCFAAdditionRate,
    costSavingsCavitiesTool,
    costSavingsHoursPerDayInput,
    costSavingsDaysPerWeekInput,
    costSavingsWeeksPerYearInput
  } = inputData;
  let errors = false;

  // ***** PRE-COLOR CONVERSION CALCULATOR || COLOR MASTERBATCH COMPARISON CALCULATOR ***** //
  // Color Masterbranch 1 Data Errors:
  if (usageRate1 === '' && letdownRatio1 === '') {
    const type = 'masterbranch';
    const id = 'masterbranch-1__usage-letdown';
    const iteration = 1;
    const dataRow = 1;
    const message = 'Color Masterbatch 1 Data: Please input either Usage Rate or Letdown Ratio.';
    const errorData = {type, id, iteration, dataRow, message};

    errors = errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('masterbranch-1__usage-letdown')) {
      document.getElementById('masterbranch-1__usage-letdown').remove();
    }
  }

  if (usageRate1 && String(usageRate1).indexOf('.') < String(usageRate1).length - 3) { 
    const type = 'masterbranch';
    const id = 'masterbranch-1__usage-decimal';
    const iteration = 1;
    const dataRow = 1;
    const message = 'Color Masterbatch 1 Data: Usage Rate can be at most 2 decimal places.';
    const errorData = {type, id, iteration, dataRow, message};

    errors = errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('masterbranch-1__usage-decimal')) {
      document.getElementById('masterbranch-1__usage-decimal').remove();
    }
  }

  if (letdownRatio1 && (typeof(letdownRatio1) !== 'number') && (letdownRatio1.toString().indexOf('.') !== -1)) {
    const type = 'masterbranch';
    const id = 'masterbranch-1__letdown-integer';
    const iteration = 1;
    const dataRow = 1;
    const message = 'Color Masterbatch 1 Data: Letdown Ratio must be an integer.';
    const errorData = {type, id, iteration, dataRow, message};

    errors = errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('masterbranch-1__letdown-integer')) {
      document.getElementById('masterbranch-1__letdown-integer').remove();
    }
  }

  if (colorConcentrate1 === '') {
    const type = 'masterbranch';
    const id = 'masterbranch-1__color-required';
    const iteration = 1;
    const dataRow = 2;
    const message = 'Color Masterbatch 1 Data: Color Masterbatch 1 Cost is a required field.';
    const errorData = {type, id, iteration, dataRow, message};

    errors = errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('masterbranch-1__color-required')) {
      document.getElementById('masterbranch-1__color-required').remove();
    }
  }

  if (colorConcentrate1 && String(colorConcentrate1).indexOf('.') < String(colorConcentrate1).length - 3) {
    const type = 'masterbranch';
    const id = 'masterbranch-1__color-decimal';
    const iteration = 1;
    const dataRow = 2;
    const message = 'Color Masterbatch 1 Data: Color Masterbatch 1 Cost can be at most 2 decimal places.';
    const errorData = {type, id, iteration, dataRow, message};

    errors = errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('masterbranch-1__color-decimal')) {
      document.getElementById('masterbranch-1__color-decimal').remove();
    }
  }
  // End

  // Color Masterbranch 2 Data Errors:
  if (usageRate2 === '' && letdownRatio2 === '') {
    const type = 'masterbranch';
    const id = 'masterbranch-2__usage-letdown';
    const iteration = 2;
    const dataRow = 1;
    const message = 'Color Masterbatch 2 Data: Please input either Usage Rate or Letdown Ratio.';
    const errorData = {type, id, iteration, dataRow, message};

    errors = errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('masterbranch-2__usage-letdown')) {
      document.getElementById('masterbranch-2__usage-letdown').remove();
    }
  }

  if (usageRate2 && String(usageRate2).indexOf('.') < String(usageRate2).length - 3) {
    const type = 'masterbranch';
    const id = 'masterbranch-2__usage-decimal';
    const iteration = 2;
    const dataRow = 1;
    const message = 'Color Masterbatch 2 Data: Usage Rate can be at most 2 decimal places.';
    const errorData = {type, id, iteration, dataRow, message};

    errors = errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('masterbranch-2__usage-decimal')) {
      document.getElementById('masterbranch-2__usage-decimal').remove();
    }
  }

  if (letdownRatio2 && (typeof(letdownRatio2) !== 'number') && (letdownRatio2.toString().indexOf('.') !== -1)) {
    const type = 'masterbranch';
    const id = 'masterbranch-2__letdown-integer';
    const iteration = 2;
    const dataRow = 1;
    const message = 'Color Masterbatch 2 Data: Letdown Ratio must be an integer.';
    const errorData = {type, id, iteration, dataRow, message};

    errors = errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('masterbranch-2__letdown-integer')) {
      document.getElementById('masterbranch-2__letdown-integer').remove();
    }
  }

  if (colorConcentrate2 === '') { 
    const type = 'masterbranch';
    const id = 'masterbranch-2__color-required';
    const iteration = 2;
    const dataRow = 2;
    const message = 'Color Masterbatch 2 Data: Color Masterbatch 2 Cost is a required field.';
    const errorData = {type, id, iteration, dataRow, message};

    errors = errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('masterbranch-2__color-required')) {
      document.getElementById('masterbranch-2__color-required').remove();
    }
  }

  if (colorConcentrate2 && String(colorConcentrate2).indexOf('.') < String(colorConcentrate2).length - 3) {  
    const type = 'masterbranch';
    const id = 'masterbranch-2__color-decimal';
    const iteration = 2;
    const dataRow = 2;
    const message = 'Color Masterbatch 2 Data: Color Masterbatch 2 Cost can be at most 2 decimal places.';
    const errorData = {type, id, iteration, dataRow, message};

    errors = errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('masterbranch-2__color-decimal')) {
      document.getElementById('masterbranch-2__color-decimal').remove();
    }
  }
  // End

  // Polymer Cost Errors:
  if (basePolymerCost === '') { 
    const type = 'base-cost';
    const id = 'polymer-const__color-required';
    const message = 'Base Polymer Cost is a required field.';
    const errorData = {type, id, message};

    errors = errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('polymer-const__color-required')) {
      document.getElementById('polymer-const__color-required').remove();
    }
  }

  if (basePolymerCost && String(basePolymerCost).indexOf('.') < String(basePolymerCost).length - 3) {  
    const type = 'base-cost';
    const id = 'polymer-const__color-decimal';
    const message = 'Base Polymer Cost can be at most 2 decimal places.';
    const errorData = {type, id, message};

    errors = errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('polymer-const__color-decimal')) {
      document.getElementById('polymer-const__color-decimal').remove();
    }
  }
  // End

  // Current Pre-Color Costs:
  if (currentPreColorCost === '') { 
    const type = 'pre-color-cost';
    const id = 'pre-color-cost__color-required';
    const message = 'Current Pre-Color Cost is a required field.';
    const errorData = {type, id, message};

    errors = errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('pre-color-cost__color-required')) {
      document.getElementById('pre-color-cost__color-required').remove();
    }
  }

  if (currentPreColorCost && String(currentPreColorCost).indexOf('.') < String(currentPreColorCost).length - 3) {   
    const type = 'pre-color-cost';
    const id = 'pre-color-cost__color-decimal';
    const message = 'Current Pre-Color Cost can be at most 2 decimal places.';
    const errorData = {type, id, message};

    errors = errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('pre-color-cost__color-decimal')) {
      document.getElementById('pre-color-cost__color-decimal').remove();
    }
  }
  // End

  // ***** CHEMICAL AGENT EXTRUSION CALCULATOR ***** //
  // Chemical Agent Extrusion Calculation Errors
  if (extResinQty === '') { 
    const type = 'ext-resin-qty-parent';
    const id = 'ext-resin-qty__color-required';
    const message = 'Resin Qty is a required field.';
    const errorData = {type, id, message};

    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('ext-resin-qty__color-required')) {
      document.getElementById('ext-resin-qty__color-required').remove();
    }
  }

  if (extResinCost === '') { 
    const type = 'ext-resin-cost-parent';
    const id = 'ext-resin-cost__color-required';
    const message = 'Resin Cost is a required field.';
    const errorData = {type, id, message};

    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('ext-resin-cost__color-required')) {
      document.getElementById('ext-resin-cost__color-required').remove();
    }
  }

  if (extUCCCFAPrice === '') { 
    const type = 'ext-resin-ucc-cfa-price-parent';
    const id = 'ext-resin-ucc-cfa-price__color-required';
    const message = 'UCC CFA Price is a required field.';
    const errorData = {type, id, message};

    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('ext-resin-ucc-cfa-price__color-required')) {
      document.getElementById('ext-resin-ucc-cfa-price__color-required').remove();
    }
  }

  if (extUCCCFADosing === '') { 
    const type = 'ext-resin-ucc-cfa-dosing-parent';
    const id = 'ext-resin-ucc-cfa-dosing__color-required';
    const message = 'UCC CFA Dosing is a required field.';
    const errorData = {type, id, message};

    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('ext-resin-ucc-cfa-dosing__color-required')) {
      document.getElementById('ext-resin-ucc-cfa-dosing__color-required').remove();
    }
  }

  if (extDensityReduction === '') { 
    const type = 'ext-resin-density-reduction-parent';
    const id = 'ext-resin-density-reduction-parent__color-required';
    const message = 'Density Reduction is a required field.';
    const errorData = {type, id, message};

    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('ext-resin-density-reduction-parent__color-required')) {
      document.getElementById('ext-resin-density-reduction-parent__color-required').remove();
    }
  }
  // End

  // ***** PURGE COST SAVE ANALYSIS ***** //
  // Material Cost Calculation Errors
  if (mcPurgingProductAmountCurrent === '') { 
    const type = 'uccleanpurge-purging-product-amount-current-parent';
    const id = 'uccleanpurge-purging-product-amount-current-parent__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('uccleanpurge-purging-product-amount-current-parent__color-required')) {
      document.getElementById('uccleanpurge-purging-product-amount-current-parent__color-required').remove();
    }
  }

  if (mcPurgingProductCostCurrent === '') { 
    const type = 'uccleanpurge-purging-product-cost-current-parent';
    const id = 'uccleanpurge-purging-product-cost-current-parent__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('uccleanpurge-purging-product-cost-current-parent__color-required')) {
      document.getElementById('uccleanpurge-purging-product-cost-current-parent__color-required').remove();
    }
  }

  if (mcPurgingProductAmountUCClean === '') { 
    const type = 'uccleanpurge-purging-product-amount-ucclean-parent';
    const id = 'uccleanpurge-purging-product-amount-ucclean-parent__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('uccleanpurge-purging-product-amount-ucclean-parent__color-required')) {
      document.getElementById('uccleanpurge-purging-product-amount-ucclean-parent__color-required').remove();
    }
  }

  if (mcPurgingProductCostUCClean === '') { 
    const type = 'uccleanpurge-purging-product-cost-ucclean-parent';
    const id = 'uccleanpurge-purging-product-cost-ucclean-parent__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('uccleanpurge-purging-product-cost-ucclean-parent__color-required')) {
      document.getElementById('uccleanpurge-purging-product-cost-ucclean-parent__color-required').remove();
    }
  }
  // End

  // Next Resin Calculation Errors
  if (mcNextResinAmountCurrent === '') { 
    const type = 'uccleanpurge-next-resin-amount-current-parent';
    const id = 'uccleanpurge-next-resin-amount-current-parent__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('uccleanpurge-next-resin-amount-current-parent__color-required')) {
      document.getElementById('uccleanpurge-next-resin-amount-current-parent__color-required').remove();
    }
  }

  if (mcNextResinCostCurrent === '') { 
    const type = 'uccleanpurge-next-resin-cost-current-parent';
    const id = 'uccleanpurge-next-resin-cost-current-parent__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('uccleanpurge-next-resin-cost-current-parent__color-required')) {
      document.getElementById('uccleanpurge-next-resin-cost-current-parent__color-required').remove();
    }
  }

  if (mcNextResinAmountUCClean === '') { 
    const type = 'uccleanpurge-next-resin-amount-ucclean-parent';
    const id = 'uccleanpurge-next-resin-amount-ucclean-parent__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('uccleanpurge-next-resin-amount-ucclean-parent__color-required')) {
      document.getElementById('uccleanpurge-next-resin-amount-ucclean-parent__color-required').remove();
    }
  }

  if (mcNextResinCostUCClean === '') { 
    const type = 'uccleanpurge-next-resin-cost-ucclean-parent';
    const id = 'uccleanpurge-next-resin-cost-ucclean-parent__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('uccleanpurge-next-resin-cost-ucclean-parent__color-required')) {
      document.getElementById('uccleanpurge-next-resin-cost-ucclean-parent__color-required').remove();
    }
  }
  // End

  // Purging Time Calculation Errors
  if (mcDowntimePurgingCurrentTime === '') { 
    const type = 'uccleanpurge-purging-time-time-current-parent';
    const id = 'uccleanpurge-purging-time-time-current-parent__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('uccleanpurge-purging-time-time-current-parent__color-required')) {
      document.getElementById('uccleanpurge-purging-time-time-current-parent__color-required').remove();
    }
  }

  if (mcDowntimePurgingCurrentCost === '') { 
    const type = 'uccleanpurge-purging-time-cost-current-parent';
    const id = 'uccleanpurge-purging-time-cost-current-parent__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('uccleanpurge-purging-time-cost-current-parent__color-required')) {
      document.getElementById('uccleanpurge-purging-time-cost-current-parent__color-required').remove();
    }
  }

  if (mcDowntimePurgingUCCleanTime === '') { 
    const type = 'uccleanpurge-purging-time-time-ucclean-parent';
    const id = 'uccleanpurge-purging-time-time-ucclean-parent__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('uccleanpurge-purging-time-time-ucclean-parent__color-required')) {
      document.getElementById('uccleanpurge-purging-time-time-ucclean-parent__color-required').remove();
    }
  }

  if (mcDowntimePurgingUCCleanCost === '') { 
    const type = 'uccleanpurge-purging-time-cost-ucclean-parent';
    const id = 'uccleanpurge-purging-time-cost-ucclean-parent__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('uccleanpurge-purging-time-cost-ucclean-parent__color-required')) {
      document.getElementById('uccleanpurge-purging-time-cost-ucclean-parent__color-required').remove();
    }
  }
  // End

  // ***** CFA COST COMPARISON CALCULATOR ***** //
  // Initial Errors
  if (initOriginalCycleTime === '') { 
    const type = 'cfi-original_cycle_time';
    const id = 'cfi-original_cycle_time__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('cfi-original_cycle_time__color-required')) {
      document.getElementById('cfi-original_cycle_time__color-required').remove();
    }
  }

  if (initOriginalShotWeight === '') { 
    const type = 'cfi-original_shot_weight';
    const id = 'cfi-original_shot_weight__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('cfi-original_shot_weight__color-required')) {
      document.getElementById('cfi-original_shot_weight__color-required').remove();
    }
  }

  if (initFoamedCycleTime === '') { 
    const type = 'cfi-foamed_cycle_time';
    const id = 'cfi-foamed_cycle_time__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('cfi-foamed_cycle_time__color-required')) {
      document.getElementById('cfi-foamed_cycle_time__color-required').remove();
    }
  }

  if (initMachineTimeCost === '') { 
    const type = 'cfi-machine_time_cost';
    const id = 'cfi-machine_time_cost__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('cfi-machine_time_cost__color-required')) {
      document.getElementById('cfi-machine_time_cost__color-required').remove();
    }
  }

  if (initResinCost === '') { 
    const type = 'cfi-resin_cost';
    const id = 'cfi-resin_cost__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('cfi-resin_cost__color-required')) {
      document.getElementById('cfi-resin_cost__color-required').remove();
    }
  }

  if (initColorantCost === '') { 
    const type = 'cfi-colorant_cost';
    const id = 'cfi-colorant_cost__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('cfi-colorant_cost__color-required')) {
      document.getElementById('cfi-colorant_cost__color-required').remove();
    }
  }

  if (initColorantAddRate === '') { 
    const type = 'cfi-colorant_cost_additional_rate';
    const id = 'cfi-colorant_cost_additional_rate__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('cfi-colorant_cost_additional_rate__color-required')) {
      document.getElementById('cfi-colorant_cost_additional_rate__color-required').remove();
    }
  }

  if (initCFACost === '') { 
    const type = 'cfi-cfa_cost';
    const id = 'cfi-cfa_cost__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('cfi-cfa_cost__color-required')) {
      document.getElementById('cfi-cfa_cost__color-required').remove();
    }
  }

  if (initCFAAdditionRate === '') { 
    const type = 'cfi_cfa_cost_additional_rate';
    const id = 'cfi_cfa_cost_additional_rate__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('cfi_cfa_cost_additional_rate__color-required')) {
      document.getElementById('cfi_cfa_cost_additional_rate__color-required').remove();
    }
  }

  if (costSavingsCavitiesTool === '') { 
    const type = 'cfi_cost_savings_cavities_per_tool';
    const id = 'cfi_cost_savings_cavities_per_tool__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('cfi_cost_savings_cavities_per_tool__color-required')) {
      document.getElementById('cfi_cost_savings_cavities_per_tool__color-required').remove();
    }
  }

  if (costSavingsHoursPerDayInput === '') { 
    const type = 'cfi_cost_savings_hours_per_day';
    const id = 'cfi_cost_savings_hours_per_day__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('cfi_cost_savings_hours_per_day__color-required')) {
      document.getElementById('cfi_cost_savings_hours_per_day__color-required').remove();
    }
  }

  if (costSavingsDaysPerWeekInput === '') { 
    const type = 'cfi_cost_savings_days_per_week';
    const id = 'cfi_cost_savings_days_per_week__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('cfi_cost_savings_days_per_week__color-required')) {
      document.getElementById('cfi_cost_savings_days_per_week__color-required').remove();
    }
  }

  if (costSavingsWeeksPerYearInput === '') { 
    const type = 'cfi_cost_savings_weeks_per_year';
    const id = 'cfi_cost_savings_weeks_per_year__color-required';
    const message = 'Required field.';
    const errorData = {type, id, message};
    
    errors = errorIDCheck(id, errorData);
  } else {
    if (document.getElementById('cfi_cost_savings_weeks_per_year__color-required')) {
      document.getElementById('cfi_cost_savings_weeks_per_year__color-required').remove();
    }
  }

  return errors
}

// ***** MISC HELPERS ***** //
// Helper used to only permit input fields to accept numeric and decmial inputs
const inputEventListener = (el) => {
  el.addEventListener('keypress', event => {
    if (event.key !== '.' && (event.which < 48 || event.which > 57)) {
      event.preventDefault();
    }
  });
}

// Disable certain input fields based on others having values
const disableInputHelper = (useageRate, letdownRate) => {
  const targetName = event.target.name;

  document.querySelector(`#${targetName}`).addEventListener('input', () => {
    const input = document.querySelector(`#${targetName}`);

    // Pre-Color Conversion Calculator || Color Masterbatch Comparison Calculator
    if (input.id === 'usage_rate_1') {
      if (input && input.value) {
        document.querySelector(`#${letdownRate}`).disabled = true;
        document.querySelector(`#${letdownRate}`).value = 0;
      } else {
        document.querySelector(`#${letdownRate}`).disabled = false;
        document.querySelector(`#${letdownRate}`).value = '';
      }
    } else if (input.id === 'letdown_ratio_1') {
      if (input && input.value) {
        document.querySelector(`#${useageRate}`).disabled = true
        document.querySelector(`#${useageRate}`).value = 0;
      } else {
        document.querySelector(`#${useageRate}`).disabled = false;
        document.querySelector(`#${useageRate}`).value = '';
      }
    } else if (input.id === 'usage_rate_2') {
      if (input && input.value) {
        document.querySelector(`#${letdownRate}`).disabled = true;
        document.querySelector(`#${letdownRate}`).value = 0;
      } else {
        document.querySelector(`#${letdownRate}`).disabled = false;
        document.querySelector(`#${letdownRate}`).value = '';
      }
    } else if (input.id === 'letdown_ratio_2') {
      if (input && input.value) {
        document.querySelector(`#${useageRate}`).disabled = true
        document.querySelector(`#${useageRate}`).value = 0;
      } else {
        document.querySelector(`#${useageRate}`).disabled = false;
        document.querySelector(`#${useageRate}`).value = '';
      }
    }
    
    // Chemical Foaming Agent:Injection
    if (input.id === 'init_foamed_shot_weight') {
      input && input.value ? document.getElementById('init_foamed_expected_shot_weight').disabled = true : document.getElementById('init_foamed_expected_shot_weight').disabled = false
    } else if (input.id === 'init_foamed_expected_shot_weight') {
      input && input.value ? document.getElementById('init_foamed_shot_weight').disabled = true : document.getElementById('init_foamed_shot_weight').disabled = false;
    }
  });
}

// Reset calculator functionality based on user clicking the reset button
const resetCalculator = () => {
  const inputs = Array.from(document.querySelectorAll('input'));
  const disabledInputs = Array.from(document.querySelectorAll('input[disabled]'));
  const errors = Array.from(document.querySelectorAll('.error-container'));
  const preColorOrMasterbatchresults = Array.from(document.querySelectorAll('.calculator-list__parent__content__data__fields__cost span'));
  const resetBtn = document.getElementById('reset');

  // Remove focus on Reset button
  resetBtn.blur()

  // Clear values and remove disabled attribute on all input fields
  inputs.forEach(input => {
    if (input.className !== 'calculator-btn') {
      input.value = '';
      input.disabled = false;
    }
  });

  // Change disabled attribute to true for any input field with the disabled attribute as well as make the value for the field set to 0
  if (document.querySelector('.calculator-list--pre-color-conversion') || document.querySelector('.calculator-list--color-masterbatch')) {
    disabledInputs.forEach(disabledInput => {
      disabledInput.disabled = false;
    });
  } else if (document.querySelector('.calculator-list--uccleanpurge')) {
    disabledInputs.forEach(disabledInput => {
      disabledInput.disabled = true;
      disabledInput.value = 0;
    });
  } else if (document.querySelector('.calculator-list--chemical-foaming-injection')) {
    disabledInputs.forEach(disabledInput => {
      disabledInput.disabled = true;
      disabledInput.value = 0;
    });
  } else if (document.querySelector('.calculator-list--chemical-agent-extrusion')) {
    disabledInputs.forEach(disabledInput => {
      disabledInput.disabled = true;
      disabledInput.value = 0;
    });
  }

  // Remove text for Pre-Color || Color Masterbatch Solutions
  preColorOrMasterbatchresults.forEach(result => {
    result.textContent = "";
  });

  // Remove error class association for any input field that has the error class
  errors.forEach(error => {
    error.remove();
  });
}

// ***** DYNAMIC CALCULATORS ***** //
// Cost Save Analysis / uccleanpurge
const costSaveAnalysisDynamicCalculator = () => {
  const mcPurgingProductCurrentAmount = document.querySelector('#mc_purging_product_amount_current').value;
  const mcPuringProductCurrentCost = document.querySelector('#mc_purging_product_cost_current').value;
  const mcPurgingProductCurrentSubtotal = document.querySelector('#mc_purging_product_subtotal_current');
  const mcPurgingProductUCCleanAmount = document.querySelector('#mc_purging_product_amount_ucclean').value;
  const mcPuringProductUCCleanCost = document.querySelector('#mc_purging_product_cost_ucclean').value;
  const mcPurgingProductUCCleanSubtotal = document.querySelector('#mc_purging_product_subtotal_ucclean');

  const mcNextResinCurrentAmount = document.querySelector('#mc_next_resin_amount_current').value
  const mcNextResinCurrentCost = document.querySelector('#mc_next_resin_cost_current').value
  const mcNextResinCurrentSubtotal = document.querySelector('#mc_next_resin_subtotal_current');
  const mcNextResinUCCleanAmount = document.querySelector('#mc_next_resin_amount_ucclean').value;
  const mcNextResinUCCleanCost = document.querySelector('#mc_next_resin_cost_ucclean').value;
  const mcNextResinUCCleanSubtotal = document.querySelector('#mc_next_resin_subtotal_ucclean');

  const mcTotalCurrent = document.querySelector('#mc_total_current');
  const mcTotalUCClean = document.querySelector('#mc_total_ucclean');
  
  const mcDowntimePurgingCurrentTime = document.querySelector('#downtime_purging_time_time_current').value;
  const mcDowntimePurgingCurrentCost = document.querySelector('#downtime_purging_time_cost_current').value;
  const mcDowntimeCurrentTotal = document.querySelector('#downtime_total_current');
  const mcDowntimePurgingUCCleanTime = document.querySelector('#downtime_purging_time_time_ucclean').value;
  const mcDowntimePurgingUCCleanCost = document.querySelector('#downtime_purging_time_cost_ucclean').value;
  const mcDowntimeUCCleanTotal = document.querySelector('#downtime_total_ucclean');

  const purgingCostCurrentTotal = document.querySelector('#purging_cost_total_current');
  const purgingCostUCCleanTotal = document.querySelector('#purging_cost_total_ucclean');

  // Current Purging Product Subtotal
  if (mcPurgingProductCurrentAmount === "" && mcPuringProductCurrentCost === "") {
    mcPurgingProductCurrentSubtotal.value = 0;
  } else if (mcPurgingProductCurrentAmount === "") {
    mcPurgingProductCurrentSubtotal.value = 0;
  } else if  (mcPuringProductCurrentCost === "") {
    mcPurgingProductCurrentSubtotal.value = 0;
  } else {
    mcPurgingProductCurrentSubtotal.value = multiplyTwoNums(parseFloat(mcPurgingProductCurrentAmount), parseFloat(mcPuringProductCurrentCost));
  }

  // UCClean Purging Product Subtotal
  if (mcPurgingProductUCCleanAmount === "" && mcPuringProductUCCleanCost === "") {
    mcPurgingProductUCCleanSubtotal.value = 0;
  } else if (mcPurgingProductUCCleanAmount === "") {
    mcPurgingProductUCCleanSubtotal.value = 0;
  } else if  (mcPuringProductUCCleanCost === "") {
    mcPurgingProductUCCleanSubtotal.value = 0;
  } else {
    mcPurgingProductUCCleanSubtotal.value = multiplyTwoNums(parseFloat(mcPurgingProductUCCleanAmount), parseFloat(mcPuringProductUCCleanCost));
  }

  // Current Next Resin Subtotal
  if (mcNextResinCurrentAmount === "" && mcNextResinCurrentCost === "") {
    mcNextResinCurrentSubtotal.value = 0;
  } else if (mcNextResinCurrentAmount === "") {
    mcNextResinCurrentSubtotal.value = 0;
  } else if  (mcNextResinCurrentCost === "") {
    mcNextResinCurrentSubtotal.value = 0;
  } else {
    mcNextResinCurrentSubtotal.value = multiplyTwoNums(parseFloat(mcNextResinCurrentAmount), parseFloat(mcNextResinCurrentCost));
  }

  // UCClean Next Resin Subtotal
  if (mcNextResinUCCleanAmount === "" && mcNextResinUCCleanCost === "") {
    mcNextResinUCCleanSubtotal.value = 0;
  } else if (mcNextResinUCCleanAmount === "") {
    mcNextResinUCCleanSubtotal.value = 0;
  } else if  (mcNextResinUCCleanCost === "") {
    mcNextResinUCCleanSubtotal.value = 0;
  } else {
    mcNextResinUCCleanSubtotal.value = multiplyTwoNums(parseFloat(mcNextResinUCCleanAmount), parseFloat(mcNextResinUCCleanCost));
  }

  // Current Purging Time Total Downtime Cost
  if (mcDowntimePurgingCurrentTime === "" && mcDowntimePurgingCurrentCost === "") {
    mcDowntimeCurrentTotal.value = 0;
  } else if (mcDowntimePurgingCurrentTime === "") {
    mcDowntimeCurrentTotal.value = 0;
  } else if  (mcDowntimePurgingCurrentCost === "") {
    mcDowntimeCurrentTotal.value = 0;
  } else {
    mcDowntimeCurrentTotal.value = multiplyTwoNums(parseFloat(mcDowntimePurgingCurrentTime), parseFloat(mcDowntimePurgingCurrentCost));
  }

  // UCClean Purging Time Total Downtime Cost
  if (mcDowntimePurgingUCCleanTime === "" && mcDowntimePurgingUCCleanCost === "") {
    mcDowntimeUCCleanTotal.value = 0;
  } else if (mcDowntimePurgingUCCleanTime === "") {
    mcDowntimeUCCleanTotal.value = 0;
  } else if  (mcDowntimePurgingUCCleanCost === "") {
    mcDowntimeUCCleanTotal.value = 0;
  } else {
    mcDowntimeUCCleanTotal.value = multiplyTwoNums(parseFloat(mcDowntimePurgingUCCleanTime), parseFloat(mcDowntimePurgingUCCleanCost));
  }

  // Current Total Material Cost
  if (mcPurgingProductCurrentAmount!== "" && mcPuringProductCurrentCost!== "" && mcNextResinCurrentAmount!== "" && mcNextResinCurrentCost!== "") {
    mcTotalCurrent.value = sumTotal(parseFloat(mcPurgingProductCurrentSubtotal.value), parseFloat(mcNextResinCurrentSubtotal.value));
  } else {
    mcTotalCurrent.value = 0;
  }

  // UCClean Total Material Cost
  if (mcPurgingProductUCCleanAmount !== "" && mcPuringProductUCCleanCost !== "" && mcNextResinUCCleanAmount !== "" && mcNextResinUCCleanCost !== "") {
    mcTotalUCClean.value = sumTotal(parseFloat(mcPurgingProductUCCleanSubtotal.value), parseFloat(mcNextResinUCCleanSubtotal.value));
  } else {
    mcTotalUCClean.value = 0;
  }

  // Current Purging Cost Steps 2 + 3
  if (mcPurgingProductCurrentAmount!== "" && mcPuringProductCurrentCost!== "" && mcNextResinCurrentAmount!== "" && mcNextResinCurrentCost!== "" && mcDowntimePurgingCurrentTime !== "" && mcDowntimePurgingCurrentCost !== "") {
    purgingCostCurrentTotal.value = sumTotal(parseFloat(mcTotalCurrent.value), parseFloat(mcDowntimeCurrentTotal.value));
  } else {
    purgingCostCurrentTotal.value = 0;
  }

  // UCClean Purging Cost Steps 2 + 3
  if (mcPurgingProductUCCleanAmount !== "" && mcPuringProductUCCleanCost !== "" && mcNextResinUCCleanAmount !== "" && mcNextResinUCCleanCost !== "" && mcDowntimePurgingUCCleanTime !== "" && mcDowntimePurgingUCCleanCost !== "") {
    purgingCostUCCleanTotal.value = sumTotal(parseFloat(mcTotalUCClean.value), parseFloat(mcDowntimeUCCleanTotal.value));
  } else {
    purgingCostUCCleanTotal.value = 0;
  }
}

// CFA Cost Comparison / chemical-foaming-injection
const cfaCostComparisonDynamicCalculator = () => {
  console.log('cfaCostComparisonDynamicCalculator');

  // Initial Values
  const initOriginalCycleTimeInput = document.getElementById('init_original_cycle_time').value;
  const initOriginalCycleTimeShotsPerHour = document.getElementById('init_original_cycle_time_shots_per_hour');
  const initOriginalShotWeightInput = document.getElementById('init_original_shot_weight').value;
  const initOriginalShotWeightGrams = document.getElementById('init_original_shot_weight_grams');
  const initFoamedCycleTimeInput = document.getElementById('init_foamed_cycle_time').value;
  const initFoamedCycleTimeShotsPerHour = document.getElementById('init_foamed_cycle_time_shots_per_hour');
  const initFoamedShotWeightInput = document.getElementById('init_foamed_shot_weight').value;
  const initFoamedShotWeightGrams = document.getElementById('init_foamed_shot_weight_grams');
  const initFoamedExpectedShotWeightInput = document.getElementById('init_foamed_expected_shot_weight').value;
  const initFoamedCalculatedFoamedShotWeight = document.getElementById('init_foamed_calculated_foamed_shot_weight');
  const initMachineTimeCostInput = document.getElementById('init_machine_time_cost').value;
  const initMachineTimeCostPerSecond = document.getElementById('init_machine_time_cost_per_second');
  const initResinCostInput = document.getElementById('init_resin_cost').value;
  const initResinCostPerGram = document.getElementById('init_resin_cost_per_gram');
  const initColorantCostInput = document.getElementById('init_colorant_cost').value;
  const initColorantCostPerGram = document.getElementById('init_colorant_cost_per_gram');
  const initColorantAddRateInput = document.getElementById('init_colorant_cost_additional_rate').value;
  const initCFACostInput = document.getElementById('init_cfa_cost').value;
  const initCFACostPerGram = document.getElementById('init_cfa_cost_per_gram');
  const initCFAAdditionalRateInput = document.getElementById('init_cfa_cost_additional_rate').value;

  // Original Process
  const oriColoredResinCost = document.getElementById('ori_colored_resin_cost');
  const oriColoredResinCostPerGram = document.getElementById('ori_colored_resin_cost_per_gram');
  const oriColoredResinCostPerShot = document.getElementById('ori_colored_resin_cost_per_shot');
  const oriMachineCostPerSecond = document.getElementById('ori_machine_cost_per_second');
  const oriMachineCostCycleTime = document.getElementById('ori_machine_cost_cycle_time');
  const oriMachineCostPerShot = document.getElementById('ori_machine_cost_per_shot');
  const oriCostNonFoamedShot = document.getElementById('ori_cost_non_foamed_shot');

  // Foamed Process
  const fpColoredResinCostFoamedShotWeight = document.getElementById('foamed_process_colored_resin_cost_foamed_shot_weight');
  const fpColoredResinCostPerGram = document.getElementById('foamed_process_colored_resin_cost_per_gram');
  const fpColoredResinCostPerShot = document.getElementById('foamed_process_colored_resin_cost_per_shot');
  const fpMachineCostPerSecond = document.getElementById('foamed_process_machine_cost_per_second');
  const fpMachineCostCycleTime = document.getElementById('foamed_process_machine_cost_cycle_time');
  const fpMachineCostPerShot = document.getElementById('foamed_process_machine_cost_per_shot');
  const fpCFACostFoamedShotWeight = document.getElementById('foamed_process_cfa_cost_foamed_shot_weight');
  const fpCFACBACost = document.getElementById('foamed_process_cba_cost');
  const fpCFACostPerShot = document.getElementById('foamed_process_cfa_cost_per_shot');
  const fpCostFoamedShot = document.getElementById('foamed_process_cost_foamed_shot');

  // Cost Savings
  const costSavingsCycleImprovementSeconds = document.getElementById('cost_savings_cycle_improvements_seconds');
  const costSavingsShotWeightReductionGrams = document.getElementById('cost_savings_shot_weight_reduction_grams');
  const costSavingsCostSavingsIncrease = document.getElementById('cost_savings_increase_per_shot');
  const costSavingsCavitiesPerToolInput = document.getElementById('cost_savings_cavities_per_tool').value;
  const costSavingsIncreasePerPart = document.getElementById('cost_savings_increase_per_part');
  const costSavingsTotalCostSavings = document.getElementById('cost_savings_total_cost_savings');

  // ***** CALCULATIONS ***** //
  // Original Cycle Time
  if (initOriginalCycleTimeInput !== "") {
    initOriginalCycleTimeShotsPerHour.value = divideTwoNums(3600, initOriginalCycleTimeInput).toFixed(0);
    oriMachineCostCycleTime.value = initOriginalCycleTimeInput;
  } else {
    initOriginalCycleTimeShotsPerHour.value = 0;
    oriMachineCostCycleTime.value = 0;
  }

  // Original Shot Weight
  if (initOriginalShotWeightInput !== "") {
    initOriginalShotWeightGrams.value = multiplyTwoNums(initOriginalShotWeightInput, 454);
    initFoamedCalculatedFoamedShotWeight.value = multiplyTwoNums(initOriginalShotWeightInput, 454);
    oriColoredResinCost.value = multiplyTwoNums(initOriginalShotWeightInput, 454);
  } else {
    initOriginalShotWeightGrams.value = 0;
    initFoamedCalculatedFoamedShotWeight.value = 0;
    oriColoredResinCost.value = 0;
  }

  // Foamed Cycle Time
  if (initFoamedCycleTimeInput !== "") {
    initFoamedCycleTimeShotsPerHour.value = divideTwoNums(3600, initFoamedCycleTimeInput);
    fpMachineCostCycleTime.value = initFoamedCycleTimeInput;
  } else {
    initFoamedCycleTimeShotsPerHour.value = 0;
    fpMachineCostCycleTime.value = 0;
  }

  // Foamed Shot Weight
  if (initFoamedShotWeightInput !== "") {
    initFoamedShotWeightGrams.value = multiplyTwoNums(initFoamedShotWeightInput, 454);
  } else {
    initFoamedShotWeightGrams.value = 0;
  }

  // Expected Shot Weight
  if (initFoamedExpectedShotWeightInput !== "" && initFoamedShotWeightInput === "") {
    initFoamedCalculatedFoamedShotWeight.value = Math.ceil(multiplyTwoNums(initOriginalShotWeightGrams.value, (1 - numPercentage(initFoamedExpectedShotWeightInput))));
  } else if (initFoamedExpectedShotWeightInput === "" && initFoamedShotWeightInput === "") {
    initFoamedCalculatedFoamedShotWeight.value = initOriginalShotWeightGrams.value;
  } else {
    initFoamedCalculatedFoamedShotWeight.value = 0;
  }

  // Machine Time Cost
  if (initMachineTimeCostInput !== "") {
    initMachineTimeCostPerSecond.value = divideTwoNums(initMachineTimeCostInput, 3600).toFixed(4);
    oriMachineCostPerSecond.value = divideTwoNums(initMachineTimeCostInput, 3600).toFixed(4);
    fpMachineCostPerSecond.value = divideTwoNums(initMachineTimeCostInput, 3600).toFixed(4);
  } else {
    initMachineTimeCostPerSecond.value = 0;
    oriMachineCostPerSecond.value = 0;
    fpMachineCostPerSecond.value = 0;
  }

  // Resin Cost 
  if (initResinCostInput !== "") {
    initResinCostPerGram.value = divideTwoNums(initResinCostInput, 454).toFixed(4);
  } else {
    initResinCostPerGram.value = 0;
  }

  // Colorant Cost
  if (initColorantCostInput !== "") {
    initColorantCostPerGram.value = divideTwoNums(initColorantCostInput, 454).toFixed(4);
  } else {
    initColorantCostPerGram.value = 0;
  }

  // CFA Cost
  if (initCFACostInput !== "") {
    initCFACostPerGram.value = divideTwoNums(initCFACostInput, 454).toFixed(4);
  } else {
    initCFACostPerGram.value = 0;
  }

  // ***** ORIGINAL PROCESS CALCULATIONS ***** //
  // Original Process: Colored Resin Cost Per Gram
  if (initResinCostInput !== "" && initColorantCostInput !== "" && initColorantAddRateInput !== "") {
    let tempResinCostPerGram = divideTwoNums(initResinCostInput, 454);
    let tempColorantCostPerGram = divideTwoNums(initColorantCostInput, 454);
    let tempColorantAddRate = numPercentage(initColorantAddRateInput);

    let calc1 = sumTotal(tempResinCostPerGram, multiplyTwoNums(tempColorantCostPerGram, tempColorantAddRate));
    let calc2 = sumTotal(1, tempColorantAddRate);
    let result = divideTwoNums(calc1, calc2);

    oriColoredResinCostPerGram.value = result.toFixed(4);
  } else {
    oriColoredResinCostPerGram.value = 0;
  }

  // Original Process: Colored Resin Cost Per Shot
  if (oriColoredResinCost.value > 0 && oriColoredResinCostPerGram.value > 0) {
    let tempColoredResinCostShotWeight = multiplyTwoNums(initOriginalShotWeightInput, 454);

    let tempResinCostPerGram = divideTwoNums(initResinCostInput, 454);
    let tempColorantCostPerGram = divideTwoNums(initColorantCostInput, 454);
    let tempColorantAddRate = numPercentage(initColorantAddRateInput);

    let calc1 = sumTotal(tempResinCostPerGram, multiplyTwoNums(tempColorantCostPerGram, tempColorantAddRate));
    let calc2 = sumTotal(1, tempColorantAddRate);
    let result = divideTwoNums(calc1, calc2);

    oriColoredResinCostPerShot.value = multiplyTwoNums(tempColoredResinCostShotWeight, result).toFixed(3);
  } else {
    oriColoredResinCostPerShot.value = 0;
  }

  // Original Process: Machine Cost Per Shot
  if (oriMachineCostPerSecond.value > 0 && oriMachineCostCycleTime.value > 0) {
    let tempMachineTimeCost = divideTwoNums(initMachineTimeCostInput, 3600);

    oriMachineCostPerShot.value = multiplyTwoNums(tempMachineTimeCost, initOriginalCycleTimeInput).toFixed(3);
  } else {
    oriMachineCostPerShot.value = 0;
  }

  // Original Process: Cost of non-foamed shot
  if (oriColoredResinCostPerShot.value !== "" && oriMachineCostPerShot.value !== "") {
    oriCostNonFoamedShot.value = sumTotal(parseFloat(oriColoredResinCostPerShot.value), parseFloat(oriMachineCostPerShot.value)).toFixed(3);
  } else {
    oriCostNonFoamedShot.value = 0;
  }

  // ***** FOAMED PROCESS CALCULATIONS ***** //
  // Foamed Process Colored Resin Cost
  if (initFoamedShotWeightInput === "") {
    fpColoredResinCostFoamedShotWeight.value = initFoamedCalculatedFoamedShotWeight.value;
    fpCFACostFoamedShotWeight.value = initFoamedCalculatedFoamedShotWeight.value;
  } else if (initFoamedShotWeightInput !== "") {
    fpColoredResinCostFoamedShotWeight.value = initFoamedShotWeightGrams.value;
    fpCFACostFoamedShotWeight.value = initFoamedShotWeightGrams.value;
  } else {
    fpColoredResinCostFoamedShotWeight.value = 0;
    fpCFACostFoamedShotWeight.value = 0;
  }

  // Foamed Process Colored Resin Cost Per Gram
  if (initResinCostInput !== "" && initColorantCostInput !== "" && initColorantAddRateInput !== "" && initCFAAdditionalRateInput !== "") {
    let tempResinCostPerGram = divideTwoNums(initResinCostInput, 454);
    let tempColorantCostPerGram = divideTwoNums(initColorantCostInput, 454);
    let tempColorantAddRate = numPercentage(initColorantAddRateInput);
    let tempCFAAdditionaRate = numPercentage(initCFAAdditionalRateInput);

    let calc1 = sumTotal(tempResinCostPerGram, multiplyTwoNums(tempColorantCostPerGram, tempColorantAddRate));
    let calc2 = 1 + tempColorantAddRate + tempCFAAdditionaRate;
    let result = divideTwoNums(calc1, calc2);

    fpColoredResinCostPerGram.value = result.toFixed(4);
  } else {
    fpColoredResinCostPerGram.value = 0;
  }

  // Foamed Process Colored Resin Cost Per Shot
  if (fpColoredResinCostFoamedShotWeight.value > 0 && fpColoredResinCostPerGram.value > 0) {
    let tempResinCostPerGram = divideTwoNums(initResinCostInput, 454);
    let tempColorantCostPerGram = divideTwoNums(initColorantCostInput, 454);
    let tempColorantAddRate = numPercentage(initColorantAddRateInput);
    let tempCFAAdditionaRate = numPercentage(initCFAAdditionalRateInput);

    let calc1 = sumTotal(tempResinCostPerGram, multiplyTwoNums(tempColorantCostPerGram, tempColorantAddRate));
    let calc2 = 1 + tempColorantAddRate + tempCFAAdditionaRate;
    let result = divideTwoNums(calc1, calc2);

    fpColoredResinCostPerShot.value = multiplyTwoNums(fpColoredResinCostFoamedShotWeight.value, result).toFixed(3);
  } else {
    fpColoredResinCostPerShot.value = 0;
  }

  // Foamed Process Machine Cost Per Shot
  if (fpMachineCostPerSecond.value > 0 && fpMachineCostCycleTime.value > 0) {
    fpMachineCostPerShot.value = multiplyTwoNums(fpMachineCostPerSecond.value, fpMachineCostCycleTime.value).toFixed(3);
  } else {
    fpMachineCostPerShot.value = 0;
  }

  // Foamed Process CFA Cost
  if (initCFACostInput !== "" && initColorantAddRateInput !== "" && initCFAAdditionalRateInput !== "") {
    let tempInitCFACost = divideTwoNums(initCFACostInput, 454);
    let calc1 = multiplyTwoNums(tempInitCFACost, numPercentage(initCFAAdditionalRateInput));
    let calc2 = 1 + numPercentage(initColorantAddRateInput) + numPercentage(initCFAAdditionalRateInput);

    let result = divideTwoNums(calc1, calc2);

    fpCFACBACost.value = result.toFixed(4);
  } else {
    fpCFACBACost.value = 0;
  }

  // Foamed Process CFA Per Shot
  if (fpCFACostFoamedShotWeight.value > 0 && fpCFACBACost.value > 0) {
    let num1 = multiplyTwoNums(divideTwoNums(initCFACostInput, 454), numPercentage(initCFAAdditionalRateInput));
    let num2 = (1 + numPercentage(initColorantAddRateInput) + numPercentage(initCFAAdditionalRateInput));
    let numResult = divideTwoNums(num1, num2);

    fpCFACostPerShot.value = multiplyTwoNums(fpCFACostFoamedShotWeight.value, numResult).toFixed(3);
  } else {
    fpCFACostPerShot.value = 0;
  }

  // Foamed Process Cost of foamed shot
  if (fpColoredResinCostPerShot.value !== "" && fpMachineCostPerShot.value !== "" && fpCFACostPerShot.value !== "") {
    fpCostFoamedShot.value = (parseFloat(fpColoredResinCostPerShot.value) + parseFloat(fpMachineCostPerShot.value) + parseFloat(fpCFACostPerShot.value)).toFixed(3);
  } else {
    fpCostFoamedShot.value = 0;
  }

  // ***** COST SAVINGS ***** //
  // Cost Savings: Cycle Improvement
  if (initOriginalCycleTimeInput !== "" && initFoamedCycleTimeInput !== "") {
    costSavingsCycleImprovementSeconds.value = subtractionTwoNums(initOriginalCycleTimeInput, initFoamedCycleTimeInput);
  } else {
    costSavingsCycleImprovementSeconds.value = 0;
  }

  // Cost Savings: hot Weight Reduction
  if (initFoamedShotWeightInput !== "") {
    costSavingsShotWeightReductionGrams.value = ((initOriginalShotWeightInput - initFoamedShotWeightInput) * 453.59).toFixed(2);
  } else if (initFoamedShotWeightInput === "") {
    costSavingsShotWeightReductionGrams.value = ((initOriginalShotWeightInput - initOriginalShotWeightGrams.value * (1 - numPercentage(initFoamedExpectedShotWeightInput))/453.59) * 453.59).toFixed(2);
  } else {
    costSavingsShotWeightReductionGrams.value = 0;
  }

  // Cost Savings: Cost savings/increase
  if (oriCostNonFoamedShot.value !== "" && fpCostFoamedShot.value !== "") {
    costSavingsCostSavingsIncrease.value = subtractionTwoNums(oriCostNonFoamedShot.value, fpCostFoamedShot.value).toFixed(3);
  }

  // Cost Savings: Per Part
  if (costSavingsCavitiesPerToolInput !== "" && costSavingsCostSavingsIncrease.value !== "") {
    costSavingsIncreasePerPart.value = divideTwoNums(costSavingsCostSavingsIncrease.value, costSavingsCavitiesPerToolInput).toFixed(3);
  } else {
    costSavingsIncreasePerPart.value = 0;
  }

  // Cost Savings: Total Cost Savings p/hr
  if (costSavingsCostSavingsIncrease.value !== "" && initFoamedCycleTimeInput !== "") {    
    costSavingsTotalCostSavings.value = multiplyTwoNums(subtractionTwoNums(oriCostNonFoamedShot.value, fpCostFoamedShot.value), initFoamedCycleTimeShotsPerHour.value).toFixed(2);
  } else {
    costSavingsTotalCostSavings.value = 0;
  }
}

// Chemical Agent Extrusion / chemical-agent-extrusion
const chemicalAgentExtrusionDynamicCalculator = () => {
  const extResinQty = document.querySelector('#ext-resin-qty').value;
  const extResinCost = document.querySelector('#ext-resin-cost').value;
  const extResinTotal = document.querySelector('#ext-resin-total');
  const extUCCCFAPrice = document.querySelector('#ext-ucc-cfa-price').value;
  const extUCCCFADosing = document.querySelector('#ext-ucc-cfa-dosing').value;
  const extUCCCFATotal = document.querySelector('#ext-ucc-cfa-total');
  const extDensityReduction = document.querySelector('#ext-density-reduction').value;
  const extResinSavings = document.querySelector('#ext-resin-savings');
  const extTotalFormSavings = document.querySelector('#ext-total-foam-savings');

  if (extResinQty !== "" && extResinCost !== "") {
    extResinTotal.value = multiplyTwoNums(extResinQty, extResinCost);
  } else {
    extResinTotal.value = 0;
    extTotalFormSavings.value = 0;
  }

  if (extResinTotal.value !== "" && extUCCCFAPrice !== "" && extUCCCFADosing !== "") {
    extUCCCFATotal.value = (numPercentage(extUCCCFADosing) * extResinTotal.value) * extUCCCFAPrice;
  } else {    
    extUCCCFATotal.value = 0;
    extTotalFormSavings.value = 0;
  }

  if (extResinQty !== "" && extDensityReduction !== "") {    
    extResinSavings.value = multiplyTwoNums(extResinQty, numPercentage(extDensityReduction));
  } else {    
    extResinSavings.value = 0;
    extTotalFormSavings.value = 0;
  }
}

// ***** CALCULATORS ***** //
// Color Masterbatch Comparison Calculator / calculator-list--color-masterbatch
const calculateMasterbranchComparison = () => {
  const usageRate1 = document.getElementById('usage_rate_1').value;
  const letdownRatio1 = document.getElementById('letdown_ratio_1').value;
  const colorConcentrate1 = document.getElementById('color_concentrate_1').value;
  const usageRate2  = document.getElementById('usage_rate_2').value;
  const letdownRatio2 = document.getElementById('letdown_ratio_2').value;
  const colorConcentrate2 = document.getElementById('color_concentrate_2').value;
  const basePolymerCost = document.getElementById('base_polymer_cost').value;
  const solutionsText = Array.from(document.querySelectorAll('.calculator-list__content__data__fields__cost span'));
  const calculateBtn = document.getElementById('calculate');

  // Inputs passed into errorsHelper for error validation feedback
  let errors = errorsHelper({
    usageRate1, 
    letdownRatio1, 
    colorConcentrate1, 
    usageRate2, 
    letdownRatio2, 
    colorConcentrate2, 
    basePolymerCost
  });

  // Remove focus on Calculate button
  calculateBtn.blur();

  // Prevent solution concatenation
  solutionsText.forEach(solutionText => {
    solutionText.textContent = '';
  });

  // Calculate Masterbatch results if no errors
  if (errors === false) {
    let colorCost1 = 0;
    let colorCost2 = 0;
    let rate1 = 0;
    let rate2 = 0;
    
    if (usageRate1 !== '' && document.getElementById('letdown_ratio_1').disabled === true) {
      rate1 = usageRate1 / 100;
      const colorCost1Solution = usageCostHelper(rate1, basePolymerCost, colorConcentrate1);
      colorCost1 = colorCost1Solution;
    } else if (document.getElementById('usage_rate_1').disabled === true && letdownRatio1 !== '') {
      const colorCost1Solution = ldrCostHelper(parseFloat(letdownRatio1), parseFloat(basePolymerCost), colorConcentrate1);
      colorCost1 = colorCost1Solution;
    }

    if (usageRate2 !== '' && document.getElementById('letdown_ratio_2').disabled === true) {
      rate2 = usageRate2 / 100;
      const colorCost2Solution = usageCostHelper(rate2, basePolymerCost, colorConcentrate2);
      colorCost2 = colorCost2Solution;
    } else if (document.getElementById('usage_rate_2').disabled === true && letdownRatio2 !== '') {
      const colorCost2Solution = ldrCostHelper(parseFloat(letdownRatio2), parseFloat(basePolymerCost), colorConcentrate2);
      colorCost2 = colorCost2Solution;
    }
    
    if (colorCost1 > colorCost2) {
      document.getElementById('solution-1-text').innerHTML += ` $${colorCost1} / weight unit`;
      document.getElementById('solution-2-text').innerHTML += ` $${colorCost2} / weight unit`;
    } else if (colorCost1 < colorCost2) {
      document.getElementById('solution-1-text').innerHTML += ` $${colorCost1} / weight unit`;
      document.getElementById('solution-2-text').innerHTML += ` $${colorCost2} / weight unit`;
    } else {
      document.getElementById('solution-1-text').innerHTML += ` $${colorCost1} / weight unit`;
      document.getElementById('solution-2-text').innerHTML += ` $${colorCost2} / weight unit`;
    }
  }
}

// Pre-Color Conversion Calculator / pre-color-conversion
const preColorConversion = () => {
  const usageRate1 = document.getElementById('usage_rate_1').value;
  const letdownRatio1 = document.getElementById('letdown_ratio_1').value;
  const colorConcentrate1 = document.getElementById('color_concentrate_1').value;
  const basePolymerCost = document.getElementById('base_polymer_cost').value;
  const currentPreColorCost = document.getElementById('current_pre-color_cost').value;
  const solutionsText = Array.from(document.querySelectorAll('.calculator-list__content__data__fields__cost span'));
  const calculateBtn = document.getElementById('calculate');

  // Inputs passed into errorsHelper for error validation feedback
  let errors = errorsHelper({
    usageRate1, 
    letdownRatio1, 
    colorConcentrate1,  
    basePolymerCost,
    currentPreColorCost
  });

  // Remove focus on Calculate button
  calculateBtn.blur();

  // Prevent solution concatenation
  solutionsText.forEach(solutionText => {
    solutionText.textContent = '';
  });

  // Calculate Pre-Color results if no errors
  if (errors === false) {
    let preColorCost = 0;
    let savings = 0;
    let rate = 0;
    
    if (usageRate1 !== '' && document.getElementById('letdown_ratio_1').disabled === true) {
      rate = usageRate1 / 100;
      preColorCost = usageCostHelper(rate, basePolymerCost, colorConcentrate1);
    } 
    
    if (document.getElementById('usage_rate_1').disabled === true && letdownRatio1 !== '') {
      preColorCost = ldrCostHelper(parseFloat(letdownRatio1), parseFloat(basePolymerCost), colorConcentrate1);
    }

    savings = currentPreColorCost - preColorCost;
    savings = Math.round(savings * 100) / 100;

    document.getElementById('solution-1-text').innerHTML += ` $${preColorCost} / weight unit`;
  
    if (savings > 0) {
      document.getElementById('solution-2-text').innerHTML += ` $${savings} / weight unit`;
    } else {
      document.getElementById('solution-2-text').innerHTML += ` $${savings} / weight unit`;
    } 
     
  }
}

// UCClean Cost Savings Analysis / uccleanpurge
const ucCleanCostSaveAnalysis = () => {
  // Material Cost Inputs
  const mcPurgingProductAmountCurrent = document.getElementById('mc_purging_product_amount_current').value;
  const mcPurgingProductCostCurrent = document.getElementById('mc_purging_product_cost_current').value;
  const mcPurgingProductAmountUCClean  = document.getElementById('mc_purging_product_amount_ucclean').value;
  const mcPurgingProductCostUCClean  = document.getElementById('mc_purging_product_cost_ucclean').value;
  const mcNextResinAmountCurrent = document.getElementById('mc_next_resin_amount_current').value;
  const mcNextResinCostCurrent = document.getElementById('mc_next_resin_cost_current').value;
  const mcNextResinAmountUCClean = document.getElementById('mc_next_resin_amount_ucclean').value;
  const mcNextResinCostUCClean = document.getElementById('mc_next_resin_cost_ucclean').value;
  // Downtime Inputs
  // const dtPurgingTimeTimeCurrent  = document.getElementById('downtime_purging_time_time_current').value;
  // const dtPurgingTimeCostCurrent = document.getElementById('downtime_purging_time_cost_current').value;
  // const dtPurgingTimeTimeUCClean = document.getElementById('downtime_purging_time_time_ucclean').value;
  // const dtpurgingTimeCostUCClean = document.getElementById('downtime_purging_time_cost_ucclean').value;

  const mcDowntimePurgingCurrentTime = document.querySelector('#downtime_purging_time_time_current').value;
  const mcDowntimePurgingCurrentCost = document.querySelector('#downtime_purging_time_cost_current').value;
  const mcDowntimePurgingUCCleanTime = document.querySelector('#downtime_purging_time_time_ucclean').value;
  const mcDowntimePurgingUCCleanCost = document.querySelector('#downtime_purging_time_cost_ucclean').value;

  // Purging Costs Totals
  const purgingCostCurrentTotal = document.querySelector('#purging_cost_total_current');
  const purgingCostUCCleanTotal = document.querySelector('#purging_cost_total_ucclean');
  // UCClean Cost Savings
  const uccleanCostSavings = document.querySelector('#ucclean_cost_savings_clean_savings');
  const uccleanCostSavingsPurgesPerMonth = document.querySelector('#ucclean_cost_savings_purges_per_month');
  const uccleanMonthlySavings = document.querySelector('#ucclean_cost_savings_monthly_savings');
  const uccleanAnnualSavings = document.querySelector('#ucclean_cost_savings_annual_savings');

  // Inputs passed into errorsHelper for error validation feedback
  let errors = errorsHelper({
    mcPurgingProductAmountCurrent,
    mcPurgingProductCostCurrent,
    mcPurgingProductAmountUCClean,
    mcPurgingProductCostUCClean,
    mcNextResinAmountCurrent,
    mcNextResinCostCurrent,
    mcNextResinAmountUCClean,
    mcNextResinCostUCClean,
    mcDowntimePurgingCurrentTime,
    mcDowntimePurgingCurrentCost,
    mcDowntimePurgingUCCleanTime,
    mcDowntimePurgingUCCleanCost
  });

  const calculateBtn = document.getElementById('calculate');

  // Remove focus on Calculate button
  calculateBtn.blur();

  if (errors === false) {
    // UCClean Cost Savings: UCClean Savings 
    if (mcDowntimePurgingCurrentTime !== "" && mcDowntimePurgingCurrentCost !== "" && mcDowntimePurgingUCCleanTime !== "" && mcDowntimePurgingUCCleanCost !== "") {
      uccleanCostSavings.value = subtractionTwoNums(parseFloat(purgingCostCurrentTotal.value), parseFloat(purgingCostUCCleanTotal.value));
    } else {
      uccleanCostSavings.value = 0;
    }

    // UCClean Cost Savings: Monthly Savings && Annual Savings
    if (uccleanCostSavings.value !== "" && uccleanCostSavingsPurgesPerMonth.value !== "") {
      uccleanMonthlySavings.value = multiplyTwoNums(parseFloat(uccleanCostSavings.value), parseFloat(uccleanCostSavingsPurgesPerMonth.value));
      uccleanAnnualSavings.value = parseFloat(uccleanMonthlySavings.value) * 12;
    } else {
      uccleanMonthlySavings.value = 0;
      uccleanAnnualSavings.value = 0;
    }
  }
}

// Is necessary
const cfaCostComparisonCalculator = () => {
  const initOriginalCycleTime = document.getElementById('init_original_cycle_time').value;
  const initOriginalShotWeight = document.getElementById('init_original_shot_weight').value;
  const initFoamedCycleTime = document.getElementById('init_foamed_cycle_time').value;
  const initMachineTimeCost = document.getElementById('init_machine_time_cost').value;
  const initResinCost = document.getElementById('init_resin_cost').value;
  const initColorantCost = document.getElementById('init_colorant_cost').value;
  const initColorantAddRate = document.getElementById('init_colorant_cost_additional_rate').value;
  const initCFACost = document.getElementById('init_cfa_cost').value;
  const initCFAAdditionRate = document.getElementById('init_cfa_cost_additional_rate').value;
  const costSavingsCavitiesTool = document.getElementById('cost_savings_cavities_per_tool').value;

  const costSavingsTotalCostSavings = document.getElementById('cost_savings_total_cost_savings');
  const costSavingsHoursPerDayInput = document.getElementById('cost_savings_hours_per_day').value;
  const costSavingsDaysPerWeekInput = document.getElementById('cost_savings_days_per_week').value;
  const costSavingsWeeksPerYearInput = document.getElementById('cost_savings_weeks_per_year').value;
  const costSavingsTotalCostSavingsPerYear = document.getElementById('cost_savings_total_cost_savings_per_year');
  const calculateBtn = document.getElementById('calculate');

  let errors = errorsHelper({
    initOriginalCycleTime,
    initOriginalShotWeight,
    initFoamedCycleTime,
    initMachineTimeCost,
    initResinCost,
    initColorantCost,
    initColorantAddRate,
    initCFACost,
    initCFAAdditionRate,
    costSavingsCavitiesTool,
    costSavingsHoursPerDayInput,
    costSavingsDaysPerWeekInput,
    costSavingsWeeksPerYearInput
  });

  calculateBtn.blur();

  if (errors === false) {
    if (costSavingsTotalCostSavings !== "" && costSavingsHoursPerDayInput.value !== "" && costSavingsDaysPerWeekInput.value !== "" && costSavingsWeeksPerYearInput.value !== "") {
      costSavingsTotalCostSavingsPerYear.value = (costSavingsTotalCostSavings.value * costSavingsHoursPerDayInput * costSavingsDaysPerWeekInput * costSavingsWeeksPerYearInput).toFixed(2);
    }
  }
}

const chemicalAgentExtrusionCalculation = () => {
  const extResinQty = document.getElementById('ext-resin-qty').value;
  const extResinCost = document.getElementById('ext-resin-cost').value;
  const extUCCCFAPrice = document.getElementById('ext-ucc-cfa-price').value;
  const extUCCCFADosing = document.getElementById('ext-ucc-cfa-dosing').value;
  const extUCCCFATotal = document.getElementById('ext-ucc-cfa-total');
  const extDensityReduction = document.getElementById('ext-density-reduction').value;
  const extResinSavings = document.getElementById('ext-resin-savings');
  const extTotalFoamSavings = document.getElementById('ext-total-foam-savings');
  const calculateBtn = document.getElementById('calculate');

  let errors = errorsHelper({
    extResinQty,
    extResinCost,
    extUCCCFAPrice,
    extUCCCFADosing,
    extDensityReduction
  });

  calculateBtn.blur();

  if (errors === false) {    
    extTotalFoamSavings.value = subtractionTwoNums(extResinSavings.value, extUCCCFATotal.value);
  }
}

const calculateColor = () => {
  const masterbatch = document.querySelector('.calculator-list--color-masterbatch');
  const preColor = document.querySelector('.calculator-list--pre-color-conversion');
  const ucClean = document.querySelector('.calculator-list--uccleanpurge');
  const chemicalFoamingInjection = document.querySelector('.calculator-list--chemical-foaming-injection');
  const chemicalAgentExtrusion = document.querySelector('.calculator-list--chemical-agent-extrusion');
  const solutionsTxt = Array.from(document.querySelectorAll('.result span'));

  if (masterbatch) {
    solutionsTxt.forEach(solution => {
      solution.textContent = '';
    });
    calculateMasterbranchComparison();
  } else if (preColor) {
    solutionsTxt.forEach(solution => {
      solution.textContent = '';
    });
    preColorConversion();
  } else if (ucClean) {
    ucCleanCostSaveAnalysis();
  } else if (chemicalFoamingInjection) {
    cfaCostComparisonCalculator();
  } else if (chemicalAgentExtrusion) {
    chemicalAgentExtrusionCalculation();
  }
}

// ***** INITIALIZATION ***** //
export const initCalculatorHandlers = () => {
  if (document.getElementById('calculate')) {
    document.getElementById('calculate').addEventListener('click', calculateColor);
  }

  if (document.getElementById('reset')) {
    document.getElementById('reset').addEventListener('click', resetCalculator);
  }

  // ***** ONLY PERMIT INPUT FIELDS TO ACCEPT NUMERIC AND DECMIAL INPUTS ***** //
  if (document.querySelector('.calculator-list--pre-color-conversion')) {
    document.querySelectorAll('input').forEach(el => {
      inputEventListener(el);
    });
  } else if (document.querySelector('.calculator-list--color-masterbatch')) {
    document.querySelectorAll('input').forEach(el => {
      el.addEventListener('keypress', event => {
        if (event.key !== '.' && (event.which < 48 || event.which > 57)) {
          event.preventDefault();
        }
      });
    });
  } else if (document.querySelector('.calculator-list--uccleanpurge')) {
    const uccleanDisabledInputs = Array.from(document.querySelectorAll('input[disabled]'));

    uccleanDisabledInputs.forEach(disabledInput => {
      disabledInput.value = 0;
    });

    document.querySelectorAll('input').forEach(el => {
      inputEventListener(el);
    });
  } else if (document.querySelector('.calculator-list--chemical-foaming-injection')) {
    const cfaDisabledInputs = Array.from(document.querySelectorAll('input[disabled]'));

    cfaDisabledInputs.forEach(disabledInput => {
      disabledInput.value = 0;
    });

    document.querySelectorAll('input').forEach(el => {
      inputEventListener(el);
    });
  } else if (document.querySelector('.calculator-list--chemical-agent-extrusion')) {
    const disabledInputs = Array.from(document.querySelectorAll('input[disabled]'));

    disabledInputs.forEach(disabledInput => {
      disabledInput.value = 0;
    });

    document.querySelectorAll('input').forEach(el => {
      inputEventListener(el);
    });
  }

  // ***** DISABLE INPUT FIELDS BASED ON disableInputHelper ***** //
  // Disable Letdown Ratio 1 when Usage Rate 1 has a value
  if (document.querySelector('#usage_rate_1')) {
    document.querySelector('#usage_rate_1').addEventListener('keypress', event => {
      disableInputHelper('usage_rate_1', 'letdown_ratio_1');
    });
  }
  
  // Disable Letdown Ratio 2 when Usage Rate 2 has a value
  if (document.querySelector('#usage_rate_2')) {
    document.querySelector('#usage_rate_2').addEventListener('keypress', event => {
      disableInputHelper('usage_rate_2', 'letdown_ratio_2');
    });
  }
  
  // Disable Usage Rate 1 when Letdown Ratio 1 has a value
  if (document.querySelector('#letdown_ratio_1')) {
    document.querySelector('#letdown_ratio_1').addEventListener('keypress', event => {
      disableInputHelper('usage_rate_1', 'letdown_ratio_1');
    });
  }
  
  // Disable Usage Rate 2 when Letdown Ratio 2 has a value
  if (document.querySelector('#letdown_ratio_2')) {
    document.querySelector('#letdown_ratio_2').addEventListener('keypress', event => {
      disableInputHelper('usage_rate_2', 'letdown_ratio_2');
    });
  }

  // Disable Expected Shot Weight Reduction when Foamed Shot Weight has a value
  if (document.querySelector('#init_foamed_shot_weight')) {
    document.querySelector('#init_foamed_shot_weight').addEventListener('keypress', event => {
      disableInputHelper();
    });
  }

  // Disable Foamed Shot Weight when Expected Shot Weight Reduction has a value
  if (document.querySelector('#init_foamed_expected_shot_weight')) {
    document.querySelector('#init_foamed_expected_shot_weight').addEventListener('keypress', event => {
      disableInputHelper();
    });
  }

  // ***** DYNAMIC CALCULATION BASED ON USER INPUT PRE calculateColor FUNCTION INVOCATION ***** //
  const uccleanpurgeDynamicFields = Array.from(document.querySelectorAll('.ucclean-dynamic'));
  const chemicalFoamingInjectionDynamicFields  = Array.from(document.querySelectorAll('.chemical-foaming-injection-dynamic'));
  const chemicalAgentExtrusionDynamicFields = Array.from(document.querySelectorAll('.chemicalAgentExtrusion-dynamic'));
  
  // Cost Save Analysis Dynamic Calculations / uccleanpurge
  uccleanpurgeDynamicFields.forEach(dynamicField => {
    dynamicField.addEventListener('keyup', event => {
      if (event.which !== 9) {
        costSaveAnalysisDynamicCalculator();
      }
    });
  });

  // CFA Cost Comparison Dynamic Calculations / chemical-foaming-injection
  chemicalFoamingInjectionDynamicFields.forEach(dynamicField => {
    dynamicField.addEventListener('keyup', event => {
      if (event.which !== 9) {
        cfaCostComparisonDynamicCalculator();
      }
    });
  });

  // Cost Analysis Extrusion Bank / chemicalAgentExtrusion
  chemicalAgentExtrusionDynamicFields.forEach(dynamicField => {
    dynamicField.addEventListener('keyup', event => {
      if (event.which !== 9) {
        chemicalAgentExtrusionDynamicCalculator();
      }
    });
  });
}